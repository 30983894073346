playList.$inject = ['gtext', 'ToastMessage'];

function playList(gtext,ToastMessage) {
    return {
        restrict: "E",
        scope: {
            playlist : "=",
            liIdx : "=", //리스트 순서
            totalCnt : "=",
            showPlaylistActionSheet : "&",  // 액션시트 선택시 부모 콜백함수
            shareVodHandler : "&"
        },
        template : require('../templates/playlist.list.html'),
        link : function(scope, event, attrs){
            scope.showParentActionSheet = function($event,oPlaylist){
                $event.stopPropagation();
                $('.icon-menu').hide();
                scope.showPlaylistActionSheet(oPlaylist);
            }

            scope.updateTotalCnt = function(){
                scope.totalCnt--;
                scope.$apply();
            }
        },
        controller : ["$scope", "$rootScope", "Util", "LoginManager", "VideoPlaylistService", "FavoriteService", "ToastMessage", "$state", "SearchService", "$sessionStorage", function($scope, $rootScope, Util, LoginManager, VideoPlaylistService, FavoriteService, ToastMessage, $state, SearchService, $sessionStorage){
            var szVodDefaultImageURL = RES_AFREECATV + "/images/mobile/common/img_default2.png"; // VOD디폴트 썸네일URL

            $scope.playlistCnt = ($scope.playlist.playlist_cnt > 999) ? "999+" : $scope.playlist.playlist_cnt;
            $('.icon-menu').hide(); //닉네임 퀵메뉴

            // 썸네일 가져오지 못했을 때
            $scope.checkThumb = function(thumb){
                var returnThumb = (thumb == null || thumb == '') ? szVodDefaultImageURL : thumb;
                return returnThumb;
            }

            // 본인이 생성한 재생목록은 닉네임 영역 보이지 않기
            setTimeout(function(){
                $scope.bMyVod = (LoginManager.getUserId() == $scope.playlist.reg_id) ? true : false; //본인 VOD 여부
                $scope.$digest()
            }, 100);

            $scope.showPlaylistActionSheet = function(oPlaylist){
                var tempAction = null;
                var bMyPlaylist = (oPlaylist.reg_id == LoginManager.getUserId()) ? true : false;

                if(bMyPlaylist){
                    tempAction= [
                        {
                            id		: "UPD_PLAYLIST",
                            name	: "수정하기",
                        },
                        {
                            id		: "DEL_PLAYLIST",
                            name	: "삭제하기",
                        },
                        {
                            id		: "SHARE",
                            name	: "공유하기",
                        }
                    ]
                }
                else{
                    tempAction= [
                        {
                            id		: "SEL_PLAYLIST",
                            name	: "전체보기",
                        },
                        {
                            id		: "DEL_PLAYLIST",
                            name	: "삭제하기",
                        },
                        {
                            id		: "SHARE",
                            name	: "공유하기",
                        }
                    ]
                }
                $rootScope.showActionSheet(tempAction, null, function(oAction){
                    switch(oAction.id){
                        case "UPD_PLAYLIST"	:
                        case "SEL_PLAYLIST" :
                            $scope.goPlaylist(oPlaylist.idx);
                            break;
                        case "DEL_PLAYLIST" :
                            $scope.delPlaylist(oPlaylist.idx, bMyPlaylist);
                            break;
                        case "SHARE":
                            oPlaylist.share_type = 'playlsit';
                            $scope.shareVodHandler(oPlaylist);
                            break;
                    }
                });
            };
            
            // 플레이어 가기
            $scope.goPlayer = function(oPlaylist){
                if(oPlaylist.playlist_cnt > 0) {
                    $rootScope.action(oPlaylist.scheme);
                    $scope.InflowLogEvent(oPlaylist);
                }
                else {
                    ToastMessage(gtext("VOD가 없습니다."));
                }
            }

            // 관리페이지 가기
            $scope.goPlaylist = function(idx){
                var form = document.createElement("form");
                var adminUrl = STBBS_AFREECATV + "/vodplaylist/index.php?nPlaylistIdx="+idx+"&szWork=update";

                form.setAttribute("method", "post");
                form.setAttribute("action", adminUrl);
                form.setAttribute("target", "_blank");
                
                var input = document.createElement('input');
                input.type = 'hidden';
                input.name = "fromUrl";
                input.value = document.location.href;
                form.appendChild(input);

                document.body.appendChild(form);
                form.submit();
            }

            // 재생목록 > 삭제하기
            $scope.delPlaylist = function(idx, bMyPlaylist){
                if(!confirm(gtext("재생목록을 삭제하시겠습니까?"))) return;

                var requestData = {
                    'szWork' : (bMyPlaylist) ? 'del_playlist_list' : 'share_playlist_bj_del_list',
                    'nPlaylistIdx' : idx,
                }

                VideoPlaylistService.callPlaylistApi(requestData).then(function(oRes){
                    ToastMessage(oRes.msg);
                    $("#playlist_list li").eq($scope.liIdx).remove();
                    $scope.updateTotalCnt();
                });
            }

            $scope.getMakingPlaylist = function(nTitleNo, nPage, nLimit){
                var requestData = {
                    'szWork' : "get_making_playlist",
                    'type' : 'all',
                    'sorttype' : 'recent_date',
                    'title_no' : nTitleNo,
                    'nPage' : nPage,
                    'nLimit' : nLimit
                };

                VideoPlaylistService.callPlaylistApi(requestData).then(function(oRes){
                    
                });
            }

            $scope.addPlaylist = function(aPlaylistIdx, nTitleNo){
                var requestData = {
                    'szWork' : "ins_playlist_info",
                    'nPlaylistIdx[]' : aPlaylistIdx,
                    'title_no' : nTitleNo
                };

                VideoPlaylistService.callPlaylistApi(requestData).then(function(oRes){
                    ToastMessage(oRes.msg);
                });
            }

            $scope.insPlaylist = function(){
                var requestData= {
                    'szWork' : "ins_playlist_list",
                    'title' : title,
                    'display' : display,
                    'share_yn' : share_yn
                }

                
            }

            $scope.InflowLogEvent = function(oVideo){
                // VOD유입경로 설정
                var arrInflowPath = ['etc'];
                var nTitleNo = oVideo.rep_title_no;
                var szBjId = '';
                // 스트리머 ID가 있을 경우에는 대입해줌
                if(oVideo.user_id){
                    szBjId = oVideo.user_id;
                }

                // 메뉴 ID값 가져옴
                var szSubMenuId = $rootScope.oSelectedSubmenu.id;
                var szVodTab = $sessionStorage.oVodTab.id;
                // 기록 > 재생목록 탭 일때만
                if(szSubMenuId == 'library' && szVodTab == 'playlist'){
                    var szPlaylistFilter = $sessionStorage.oPlaylistFilter.id;
                    switch(szPlaylistFilter){
                        case 'all':
                            if(LoginManager.getUserId() == oVideo.reg_id) {
                                arrInflowPath = ['vod', 'playlist', 'total', 'created_list'];
                            }
                            else{
                                arrInflowPath = ['vod', 'playlist', 'total', 'added_list'];
                            }
                            break;
                        case 'origin':
                            arrInflowPath = ['vod', 'playlist', 'created_list'];
                            break;
                        case 'share':
                            arrInflowPath = ['vod', 'playlist', 'added_list'];
                            break;
                    }
                }

                // VOD 유입경로 전송
                $rootScope.sendInflowLog('VOD', szBjId, nTitleNo, arrInflowPath);
            }
        
            // 채널 가기
            $scope.goBjStation = function($event, szUserId){
                $event.stopPropagation();
                Util.goStation(szUserId);
            }

            $('.content_wrap').off('click').on('click', function(e) {
                // 닉네임 이외의 영역 클릭 시 닫기
                if($(e.target).closest('.nick').length == 0) {
                    $('.icon-menu').hide();
                }
            });

            // 닉네임 클릭
            $scope.clickNick = function(userId, event) {
                const $iconMenu = $(event.target).parent().siblings('.icon-menu');
                const display = $iconMenu.css("display");

                $('.icon-menu').hide();

                if(display != 'none') return; //닉네임 메뉴가 열려있는 상태이면 닫고 끝냄
 
                $iconMenu.show();

                FavoriteService.check(userId).then(function(bFavorite) {
                    $scope.bFavorite = bFavorite;
                    $scope.$digest();
                });
            }

            // 쪽지
            $scope.clickNote = function(userId) {
                $('.icon-menu').hide();

                const url = $state.href('note.write2', {id: userId});
                window.open(url,'_blank');
            }

            // 즐겨찾기
            $scope.clickFavorite = function(userId) {
                $('.icon-menu').hide();

                ($scope.bFavorite) ? $scope.delFavorite(userId) : $scope.addFavorite(userId);
            }

            // 즐겨찾기 삭제
            $scope.delFavorite = function(userId) {
                FavoriteService.delete(userId).then(function(data){
                    ToastMessage(data.message);
                })
                .catch(function (response) {
                    ToastMessage(response.data.message);
                });
            }

            // 즐겨찾기 추가
            $scope.addFavorite = function(userId) {
                FavoriteService.add(userId).then(function(data){
                    ToastMessage(data.message);
                })
                .catch(function(response) {
                    ToastMessage(response.data.message);
                });
            }

            // 유저 검색
            $scope.searchUser = function(userId) {
                $('.icon-menu').hide();

                const uId = ($rootScope.member) ? $rootScope.member.user_id : null;

                SearchService.checkWord({
                    d: userId,
                    uid: uId,
                    stype: 'ui', //닉네임 ICT 검색
                    acttype: 'vod'
                }).then(function (data) {
                    if (data.isIllegal === 1) {
                        toastr.error(`'${data.stopword}'는(은) 성적 불법촬영물등과 관련된 부적절한 키워드로 인해 제한되었습니다. 다른 검색어를 입력해 주시기 바랍니다.`);
                    }
                    else if (data.isstop === '1') {
                        ToastMessage(data.stopword + gtext('은(는) 검색을 제한한 단어이므로 검색하실 수 없습니다.'));
                    }
                    else {
                        $state.go('search', {query: userId});
                    }
                }).catch(function (errors) {
                    ToastMessage(gtext('검색시스템에 문제가 발생했습니다.'));
                });
            }
        }]
    }
}

module.exports = playList;