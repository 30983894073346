const { readyException } = require("jquery");

Util.$inject = ['$rootScope', '$location', '$state', 'AfreecaStorageFactory', 'LoginManager', '$sessionStorage', '$sce', 'gtext', 'CookieFactory'];

function Util($rootScope, $location, $state, AfreecaStorageFactory, LoginManager, $sessionStorage, $sce, gtext, CookieFactory) {
	var supportLanguage = ['en_US', 'ko_KR', 'zh_CN', 'zh_TW', 'ja_JP', 'th_TH'];
	var scrollCallback = null;
	var popup;

	// oScrollBind에 스크롤이벤트담기
	var oScrollBind;
	var registScrollEvent = function () {
		// 스크롤이벤트가 oScrollBind에 등록되어있지 않을때 등록하기
		if (!oScrollBind) {
			oScrollBind = $(window).bind('scroll', function () {
				// 기본적으로 document Height가 window Height보가 크고, 스크롤이 맨하단에 왔을때 실행
				if (($(document).height() > $(window).height()) && ($(window).scrollTop() >= ($(document).height() - $(window).height() - 200))) {
					// 콜백이 있으면
					scrollCallback ? scrollCallback() : null;
				}
			});
		}
	}

	return {
		//afreeca scheme  파서 ( a 태그이용)
		parseURL: function (url) {
			if (!url) {
				return false;
			}

			var parser = document.createElement('a');
			parser.href = url.replace(AFREECATV_APP_SCHEME, '//').replace(SERVICE_APP_SCHEME, '//');
			return {
				source: url,
				protocol: parser.protocol.replace(':', ''),
				host: parser.hostname,
				port: parser.port,
				query: parser.search,
				params: (function () {
					var ret = {},
						seg = parser.search.replace(/^\?/, '').split('&'),
						len = seg.length,
						i = 0,
						s;

					for (; i < len; i++) {
						if (!seg[i]) {
							continue;
						}
						s = seg[i].split('=');
						ret[s[0]] = decodeURIComponent(s[1]);
					}
					return ret;
				})(),
				pathname: parser.pathname,
				file: (parser.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
				hash: parser.hash.substr(1),
				path: parser.pathname.replace(/^([^\/])/, '/$1'),
				relative: (parser.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
				segments: parser.pathname.replace(/^\//, '').split('/')
			}
		},
		//scheme 실행
		action: function (szScheme, id) {
			var aUrl = this.parseURL(szScheme);
			switch (aUrl.host) {
				case 'go':
					$.get(M_AFREECATV + "/ma.php?category=mobileweb&action=page&label=" + aUrl.segments[0]);
					switch (aUrl.segments[0]) {
						case 'webview':
							{
								location.href = aUrl.params['url'];
							}
							break;
						case 'favorite':
							if (LoginManager.isLogin() == false) {
								$rootScope.login('favorite');
								break;
							}
							$state.go('favorite');
							break;
						case 'recent':
							if (LoginManager.isLogin() == false) {
								$rootScope.login('recent');
								break;
							}
							$state.go('recent');
							break;
						case 'live':
							if ($.isEmptyObject(aUrl.params)) {
								$state.go('home');
							}
							// 파라메터가 있을시 : 테마, 그룹, 카테고리 이동시 rootScope값을 변경시켜 home.js에서 처리할수있도록 셋팅
							else {
								$state.go('home', {
									oParams: aUrl.params
								}, {
									reload: true
								});

							}
							break;
						case 'video':
								$state.go(aUrl.segments[0], {oParams: aUrl.params});
							break;
						default:
							{
								$state.go(aUrl.segments[0]);
							}
							break;
					}
					break;
				case 'list':
					switch (aUrl.segments[0]) {
						case 'live':
							$sessionStorage.oListLiveParams = null;
							$state.go('list', {
								oParams: {
									group_type: aUrl.params['group_type'],
									title: aUrl.params['title']
								}
							});
							break;
					}
					break;
				case 'browser':
					switch (aUrl.segments[0]) {
						//채널로 가기
						case 'station':
							var url = aUrl.params['url'];
							this.popup(M_AFREECATV + '/station.html?url=' + encodeURIComponent(url));
							break;
						case 'webview':
						case 'inApp':
						case 'shopfreeca':
						case 'external':
							var url = aUrl.params['url'];
							//알려 드립니다 일 경우,플랫폼 모바일웹 처리함.
							if(url == M_AFREECATV + "/notice/a/list/platform/{platform}"){
								url = url.replace("{platform}", "mobileweb");
							}
							else{
								url = url.replace("{platform}", "google");	
							}
							url = url.replace("{user_id}", ($rootScope.member && $rootScope.member.user_id) ? $rootScope.member.user_id : 'guest');
							url = url.replace("{version}", '1');
							url = url.replace("{model}", 'web');
							url = url.replace("{os_version}", 'web');
							if (/iphone|ipod|ipad/i.test(navigator.userAgent)) {
								url = url.replace("TBOX20131007000000", 'TBOX20131007000002');
								url = url.replace("{device}", '1');
							} else {
								url = url.replace("TBOX20131007000002", 'TBOX20131007000000');
								url = url.replace("{device}", '2');
							}								
							this.popup(url);
							break;
						case 'refresh':
							break;
						case 'errorRefresh':
							break;
						case 'close':
							break;
						case 'historyBack':
							break;
					}
					break;
				case 'player':
					switch (aUrl.segments[0]) {
						case 'live':
							var nBroadNo = aUrl.params['broad_no'] ? aUrl.params['broad_no'] : '';
							//모바일웹 도메인 전환으로 인한 m.afreecatv.com -> play.afreecatv.com 으로 이동
							window.location = `${PLAY_AFREECATV}/${aUrl.params['user_id']}/${nBroadNo}`;
							break;
						case 'video':
						case 'vod':
                        case 'catch':
							//스포츠
							if (aUrl.params['type'] == 'PC_SPORTS') {
								if (aUrl.params['station_no'] === '1') {
									this.popup(M_AFREECATV + '/sports/#/player/' + aUrl.params['title_no'])
								} else {
									this.popup(M_AFREECATV + '/sports/a/view/bbs_no/' + aUrl.params['title_no'])
								}
							} else if (aUrl.params['type'] == 'SPORTS') {
								this.popup(M_AFREECATV + '/#/player/' + aUrl.params['title_no'])
							}
							//일반,아카이브(params.theme는 later, favorite, playlist 류들)
							else {
								if(typeof aUrl.params['nPlaylistIdx'] != "undefined"){
									this.popup(VOD_AFREECATV + '/player' + 
											'/' + aUrl.params['title_no'] +
											'/playlist' +
											'/' + aUrl.params['nPlaylistIdx']
									);
								} 
								else if(typeof aUrl.params['vod_idx'] != "undefined") {
									this.popup(M_AFREECATV + '/' + 
										aUrl.params['user_id'] +
										'/video/' + aUrl.params['bbs_no'] +
										'/' + aUrl.params['title_no'] +
										'/hotidx/' + (aUrl.params['vod_idx'])
									);
								}
								else {
									if (aUrl.params['type'] && aUrl.params['type'].toLowerCase() == 'catch') {
										this.popup(VOD_AFREECATV + '/player' + 
											'/' + aUrl.params['title_no'] +
											'/catch'
										);
									} else {
										var vodPath = (typeof aUrl.params['theme'] != 'undefined') ? '/' + aUrl.params['theme'] : '';
										this.popup(VOD_AFREECATV + '/player' + 
											'/' + aUrl.params['title_no'] +
											vodPath
										);
									}
								}

							}
							break;

					}
					break;
				case 'station':
					//11-13일 이후로 사용안함
					switch (aUrl.segments[0]) {
						// station link 으로 이동
						case 'link':
							var url = aUrl.params['url'];
							this.popup(M_AFREECATV + '/station.html?url=' + encodeURIComponent(url));
							break;
							// station home 으로 이동
						case 'home':
							if (aUrl.params['user_id']) {
								var url = M_AFREECATV + '/' + aUrl.params['user_id'];
								this.popup(M_AFREECATV + '/station.html?url=' + encodeURIComponent(url));
							}
					}
					break;
					// tab이동
				case 'tab':
					switch (aUrl.segments[0]) {
						case 'live':
							$state.go('live');
							break;
						case 'vod':
							$state.go('video');
							break;
						default:
							$state.go(aUrl.segments[0]);
							break;
					}
					break;
					// 검색
				case 'search':
					AfreecaStorageFactory.setItem('query', aUrl.params['keyword']);
					$state.go('search');
					$('#searchText').blur();
					break;
					// 카테고리이동
				case 'category':
					AfreecaStorageFactory.setItem('oLiveCategory', angular.toJson(aUrl.params));
					$state.go('live');
					break;
				case 'login':
					switch (aUrl.segments[0]) {
						case 'requireLogin':
							$rootScope.login();
							break;
					}
				case 'menu':
					switch (aUrl.segments[0]) {
						case 'scan':
							var id = aUrl.params['id'];
							angular.forEach($rootScope.groups, function (group, key) {
								angular.forEach(group.menus, function (menu, key) {
									if (menu.id == id) {
										action(menu.scheme);
										return true;
									}

								});

							});
							break;
					}
					break;
					// 팝업
				case 'alert':
					if ('broad' == aUrl.segments[0]) {
						return JSON.parse(decodeURIComponent(aUrl.params['list']));
					}
					break;
			}
		},
		// VOD유입경로 직접 이벤트 바인딩 한 경우 처리
		directVodInflowPath: function(bjId, contentNo, addData){
			// path1: main, path2: hotissue 형태를 -> main, hotissue 형태로 컨버팅
			var arrInflowPath = this.convertInflowPathArray(addData);
			const undefineVal = undefined;
			let vodIdx = null;
			let list_type = null;
			let skey = null;
			// 라이브 메인 > 핫이슈 VOD 인덱스 로그
			if(addData['vod_idx']) {
				vodIdx = addData['vod_idx'];
			}
			//
			if(typeof addData['list_type'] != "undefiend"){
				list_type = addData['list_type']
			}
			// 검색 키워드 필드 추가
			if(typeof addData['skey'] != "undefiend"){
				skey = addData['skey']
			}

			// 유입경로 전달
			this.sendInflowLog('VOD', bjId, contentNo, arrInflowPath, undefineVal, undefineVal, undefineVal, vodIdx, list_type, skey);
		},
		 // VOD유입경로 이벤트 바인딩 유형에 따라 직접 이벤트 바인딩 한 경우 키 값이 없는 배열로 컨버팅 시켜주는 함수
		convertInflowPathArray : function(addData){
			var arrInflowPath = ['etc'];
			if(addData['path1'] && addData['path2'] && addData['path3'] && addData['path4'] && addData['path5']){
				arrInflowPath = [addData['path1'], addData['path2'], addData['path3'], addData['path4'],addData['path5']];
			}
			else if(addData['path1'] && addData['path2'] && addData['path3'] && addData['path4']){
				arrInflowPath = [addData['path1'], addData['path2'], addData['path3'], addData['path4']];
			}else if(addData['path1'] && addData['path2'] && addData['path3']){
				arrInflowPath = [addData['path1'], addData['path2'], addData['path3']];
			}else if(addData['path1'] && addData['path2']){
				arrInflowPath = [addData['path1'], addData['path2']];
			}else if(addData['path1']){
				arrInflowPath = [addData['path1']];
			}

			return arrInflowPath;
		},

		// station으로 이동
		goStation: function (szUrl) {
			if (szUrl.indexOf('user_id') == -1) {
				this.popup(M_AFREECATV + '/station.html?url=' + encodeURIComponent(M_AFREECATV + '/' + szUrl));
			} else {
				var parser = document.createElement('a');
				parser.href = szUrl;
				var szParams = parser.search.substr(1)
				var params = szParams.split('&');
				var aParam	=[]
				for ( var i=0; i<params.length; i++ ) {
					var param = params[i].split('=');
					aParam[param[0]] =  param[1];
				}
				this.popup(M_AFREECATV + '/station.html?url=' + encodeURIComponent(M_AFREECATV + '/' + aParam['user_id']));
			}
		},

		/**
		 * 새 페이지 열기 동작 래핑 유틸 함수. 두 번째 인자가 전달되지 않으면 새 탭에서 열린다.
		 * @param {string} url 열릴 페이지 URL
		 * @param {string} name 열릴 대상 탭 이름 (없으면 _blank)
		 */
		popup: function (url, name) {
			name = (name === undefined) ? '_blank' : name;
			window.open(url, name);
		},

		/**
		 * 새 페이지를 연 다음 그 페이지가 닫혔을 때 현재 페이지를 새로고침 한다.
		 * @param {*} url 
		 */
		popupWithReloadOnClose: function (url) {
			const popupWindow = window.open(url, '_blank');

			// 열었던 창이 닫힐 경우 현재 창 새로고침
			const isClosedInterval = setInterval(() => {
				if (!popupWindow || popupWindow.closed) {
					clearInterval(isClosedInterval);
					location.reload();
				}
			}, 500);
		},

		//팝업열때 post 로 parameter 보내기 위해 사용
		popupWithForm: function (form) {
			window.open("", 'newPopup');
			form.target = 'newPopup';
			form.submit();
		},

		// undifind값대신 ''빈값으로 return
		getParam: function (oParam) {
			return szParam ? szParam : '';
		},

		/**
		 * 더보기
		 * 중복 스크롤 callback 호출되는 현상을 방지하기위해 스크롤이벤트를 저장해놓는다. 이미 저장 되어있으면 pass
		 * @function callback
		 */
		lastItem: function (callback) {
			registScrollEvent();
			scrollCallback = callback;
		},
		// 초로된 시간을 time fomat으로 변경
		secondsToTime: function (nSeconds) {
			// 빈값이면 return false;
			if (!nSeconds) {
				return false;
			}
			var d = Number(nSeconds / 1000);
			var h = Math.floor(d / 3600);
			var m = Math.floor(d % 3600 / 60);
			var s = Math.floor(d % 3600 % 60);
            var time = "";
            
            if (h !== 0) {
                 time = h + ":";
            }
            
            time += h > 0 ? String(m).padStart(2, '0') :  String(m);
            time += ":";
            time += String(s).padStart(2, '0');
            
			return time;

		},

		/**
		 * 앱에서 라이브 시청
		 * @param {string} bj_id
		 * @param {number} broad_no
		 */
		live: function (bj_id, broad_no) {
			//앱으로 시청PV
			$.get(M_AFREECATV + "/ma.php?category=click&action=app&label=live");
			if (bj_id) {
				afreeca.link.liveApp(bj_id, broad_no, function () {
					if (/android/i.test(navigator.userAgent)) {
						location.href = 'market://details?id=kr.co.nowcom.mobile.afreeca';
					} else if (/iphone|ipod|ipad/i.test(navigator.userAgent)) {
						location.href = '//itunes.apple.com/kr/app/apeurikatv-afreecatv/id334185830?mt=8';
					} else {
						location.href = 'https://play.google.com/store/apps/details?id=kr.co.nowcom.mobile.afreeca';
					}
				});
			} else {
				afreeca.link.start();
			}
		},
		app: function () {
			$.get(M_AFREECATV + "/ma.php?category=click&action=app&label=start");
			afreeca.link.start(function () {
				if (/android/i.test(navigator.userAgent)) {
					location.href = 'market://details?id=kr.co.nowcom.mobile.afreeca';
				} else if (/iphone|ipod|ipad/i.test(navigator.userAgent)) {
					location.href = '//itunes.apple.com/kr/app/apeurikatv-afreecatv/id334185830?mt=8';
				} else {
					location.href = 'https://play.google.com/store/apps/details?id=kr.co.nowcom.mobile.afreeca';
				}
			});
		},

		/**
		 * 문자열 포멧
		 * @returns {String}
		 */
		printf: function () {
			if (!arguments || arguments.length < 1 || !RegExp) {
				return;
			}
			var str = arguments[0];
			var re = /([^%]*)%('.|0|\x20)?(-)?(\d+)?(\.\d+)?(%|b|c|d|u|f|o|s|x|X)(.*)/;
			var a = b = [],
				numSubstitutions = 0,
				numMatches = 0;
			while (a = re.exec(str)) {
				var leftpart = a[1],
					pPad = a[2],
					pJustify = a[3],
					pMinLength = a[4];
				var pPrecision = a[5],
					pType = a[6],
					rightPart = a[7];

				//alert(a + '\n' + [a[0], leftpart, pPad, pJustify, pMinLength, pPrecision);

				numMatches++;
				if (pType == '%') {
					subst = '%';
				} else {
					numSubstitutions++;
					if (numSubstitutions >= arguments.length) {
						alert('Error! Not enough function arguments (' + (arguments.length - 1) + ', excluding the string)\nfor the number of substitution parameters in string (' + numSubstitutions + ' so far).');
					}
					var param = arguments[numSubstitutions];
					var pad = '';
					if (pPad && pPad.substr(0, 1) == "'")
						pad = leftpart.substr(1, 1);
					else if (pPad)
						pad = pPad;
					var justifyRight = true;
					if (pJustify && pJustify === "-")
						justifyRight = false;
					var minLength = -1;
					if (pMinLength)
						minLength = parseInt(pMinLength);
					var precision = -1;
					if (pPrecision && pType == 'f')
						precision = parseInt(pPrecision.substring(1));
					var subst = param;
					if (pType == 'b')
						subst = parseInt(param).toString(2);
					else if (pType == 'c')
						subst = String.fromCharCode(parseInt(param));
					else if (pType == 'd')
						subst = parseInt(param) ? parseInt(param) : 0;
					else if (pType == 'u')
						subst = Math.abs(param);
					else if (pType == 'f')
						subst = (precision > -1) ? Math.round(parseFloat(param) * Math.pow(10, precision)) / Math.pow(10, precision) : parseFloat(param);
					else if (pType == 'o')
						subst = parseInt(param).toString(8);
					else if (pType == 's')
						subst = param;
					else if (pType == 'x')
						subst = ('' + parseInt(param).toString(16)).toLowerCase();
					else if (pType == 'X')
						subst = ('' + parseInt(param).toString(16)).toUpperCase();
				}
				str = leftpart + subst + rightPart;
			}
			return str;
		},

		/**
		 * 안드로이드 3.0 이상인지
		 * @returns {undefined}
		 */
		isGingerbreadHigher: function () {
			var szUserAgent = navigator.userAgent.toLowerCase();
			if (szUserAgent.indexOf('android') > -1) {
				var nAndroidMajorVersion = parseInt((szUserAgent.match(/android\s([0-9.]*)/i)[1]).split('.')[0]);
				if (nAndroidMajorVersion < 3) {
					return false;
				}
			}
			return true;
		},


		getOS: function () {
			var device;
			if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
				device = 'ios';
			} else if (navigator.userAgent.match(/Android/i)) {
				device = 'android';
			} else if (navigator.userAgent.match(/BlackBerry/i)) {
				device = 'blackberry';
			} else if (navigator.userAgent.match(/IEMobile|WPDesktop/i)) {
				device = 'window';
			} else {
				device = 'etc';
			}
			return device;
		},
		isLowWithCurrentAppVersion: function (targetVersion, currentVersion) {
			if (!targetVersion) return false;

			var target = targetVersion.split('.');
			var current = currentVersion.split('.');

			// 비교하고자 하는 버전이 더 클 경우 true, 작을 경우  false
			if(Number(target[0]) > Number(current[0])) { return true; }
			if(Number(target[0]) < Number(current[0])) { return false; }

			if(Number(target[1]) > Number(current[1])) { return true; }
			if(Number(target[1]) < Number(current[1])) { return false; }

			if(Number(target[2]) > Number(current[2])) { return true; }
			if(Number(target[2]) < Number(current[2])) { return false; }

			// 버전이 같은 경우
			return false;
		},
		getOSVersion: function (version) {
			var ticketName = (typeof TICKET_COOKIE_NAME != 'undefined') ? TICKET_COOKIE_NAME : $rootScope.$const.TICKET_COOKIE_NAME;
			var versionInfo = [];
			versionInfo['version'] = "1.0.0";
			versionInfo['sys_type'] = "webm";
			versionInfo['os_version'] = "web";
			versionInfo['model'] = "web";
			versionInfo['os'] = "";
			versionInfo['device_type'] = this.getOS === 'ios' ? 1 : 2; //0: PC, 1: IOS, 2: Android
			versionInfo['location'] = "item";
			versionInfo['payment_type'] = "item";
			versionInfo['ticket'] = CookieFactory.get(ticketName);
			versionInfo['store'] = "";

			var uagent = navigator.userAgent.toLocaleLowerCase();
			var afreecaUA = (typeof AFREECATV_WEBVIEW_AGENT != 'undefined') ? AFREECATV_WEBVIEW_AGENT : $rootScope.$const.AFREECATV_WEBVIEW_AGENT;
			var soopUA = (typeof SOOPLIVE_WEBVIEW_AGENT != 'undefined') ? SOOPLIVE_WEBVIEW_AGENT : $rootScope.$const.SOOPLIVE_WEBVIEW_AGENT;
			var regex = new RegExp('(' + afreecaUA + '|' + soopUA + ')[2]?\/([0-9.]+)', 'i');
			//var regex = /(afreeca webview\d?\/(\d+\.\d+(.\d+)?))/ig;
			var osRegex = /(android|OS)\s([0-9_]*)(;|like)*\s([a-zA-Z0-9-]*)/ig;
			var storeRegex = /store\/([a-zA-Z]+);?/ig;

			var matchs = regex.exec(uagent);
			var osMatchs = osRegex.exec(uagent); 
			var storeMatchs = storeRegex.exec(uagent);			

			if(matchs === null) {
				return versionInfo;
			}

			var compare = this.isLowWithCurrentAppVersion(version, matchs[2]);
			
			versionInfo['version'] = matchs[2];
			versionInfo['compare'] = compare;

			if(osMatchs === null) {
				return versionInfo;
			}
				
			versionInfo['os'] = this.getOS() === 'android' ? '&os=aos' : '&os=ios';
			versionInfo['os_version'] = osMatchs[2].replace("_", ".");
			versionInfo['model'] = osMatchs[4];
			versionInfo['sys_type'] = "app";
			
			if(storeMatchs !== null) {
				versionInfo['store'] = storeMatchs[1];
			}

			return versionInfo;
		},
		isPlayStore: () => {
			const ua = navigator.userAgent;
			const regex = /isPlayStore=([yn]);?/gi.exec(ua);

			if (!regex) return false;
			return regex[1].toLowerCase() === 'y';
		},
		callbackWindowPopup: function (url, callback) {

			popup = window.open(url);
			var checkPopup = function () {
				if (popup && popup.closed) {
					if (callback)
						callback();
				} else {
					setTimeout(checkPopup, 500);
				}
			}
			setTimeout(checkPopup, 500)
		},
		// Scroll 이동
		moveScroll: function (nPosition) {
			setTimeout(function () {
				$('html, body').stop().scrollTop(nPosition);
				nPosition = 0;
			}, 10);
		},

		//모바일 고객센터 이동
		goReportHelp: function () {
			location.replace(M_AFREECATV + '/report/help/a/main/szKind/TBOX20131007000000/szApp/mafreecatv/szUserId/jooooo123/szVersion/1/szModel/web/szOsVer/web');
			//location.href = M_AFREECATV + '/report/help/a/main/szKind/TBOX20131007000000/szApp/mafreecatv/szUserId/jooooo123/szVersion/1/szModel/web/szOsVer/web';
		},


		// 언어 셋팅 (default en)
		language: function () {
			// 중국어
			if (/^zh/ig.test(navigator.language)) {
				return 'zh';
			}
			// 영어
			else if (/^en/ig.test(navigator.language)) {
				return 'en';
			}
			// 한국어
			else if (/^ko/ig.test(navigator.language)) {
				return 'ko';
			}
			// default : en
			else {
				return 'en';
			}
		},

		// 국가 가져오기
		getNationByNationalCode: function (nNationalCode) {
			// 중국
			if ($.inArray(nNationalCode, [156, 158, 344]) > -1) {
				return 'chinese';
			}
			// 한국
			else if ($.inArray(nNationalCode, [410]) > -1) {
				return 'korean';
			}
			// 기본
			else {
				return 'english';
			}
		},

		//popLayer
		setPopLayer: function (title, content_tag) {
			$rootScope.layer_title = title;
			$rootScope.layer_content = $sce.trustAsHtml(content_tag);
			$rootScope.bPopLayer = true;
		},
		// 3일전꺼 까지는 상대날짜로 나타냄, 그 이후는 절대날짜 yyyy-mm-dd 형식으로 보여줌
		regDateConvert: function (reg_date) {
            var d = new Date(reg_date * 1000);
            var now = new Date();
            var diffTimeStamp = Math.abs(now - d) / 1000;

            var unitSecond = {
            	day: 86400, // feel free to add your own row
            	hour: 3600,
            	minute: 60,
            	second: 1
            };

            var relativeDay = Math.floor(diffTimeStamp / unitSecond.day);
            var relativeHour = Math.floor(diffTimeStamp / unitSecond.hour);
            var relativeMinute = Math.floor(diffTimeStamp / unitSecond.minute);
            var relativeSecond = Math.floor(diffTimeStamp / unitSecond.second);

            if (relativeDay < 4) {
            	if (relativeDay > 0) {
            		return relativeDay + gtext('일 전');
            	} else if (relativeHour > 0) {
            		return relativeHour + gtext('시간 전');
            	} else if (relativeMinute > 0) {
            		return relativeMinute + gtext('분 전');
            	} else if (relativeSecond > 0) {
            		return relativeSecond + gtext('초 전');
            	}
            }

            // 상대적 날짜 표시에 해당 안되면 절대적 날짜로 표기
            var date = [
            	d.getFullYear(),
            	('0' + (d.getMonth() + 1)).slice(-2),
            	('0' + d.getDate()).slice(-2)
            ].join('-');
            return date;
		},
		getPreferredLocale: function (time) {
			var lang = window.navigator.language || window.navigator.userLanguage;
			var lang = lang.replace('-', '_');
			var aLang = lang.split('_');
			var nationCode = aLang[1] ? aLang[1].toUpperCase() : null;

			if (nationCode == 'HANS') {
				nationCode = 'CN';
			} else if (nationCode == 'HANT') {
				nationCode = 'TW';
			}

			//4자 매칭
			var code = aLang[0] + (nationCode ? ('_' + nationCode) : '');
			if ($.inArray(code, supportLanguage) > 0) {
				return code;
			}

			//앞 2자 매칭(언어매칭)
			for (var i = 0; i < supportLanguage.length; i++) {
				var patt = new RegExp(aLang[0]);
				if (patt.test(supportLanguage[i])) {
					return supportLanguage[i];
				}
			}

			return supportLanguage[0];
		},
		/**
		 * 한국어
		 * @param {type} time
		 * @returns {Boolean}
		 */
		isKorean: function (time) {
			return (this.getPreferredLocale() == 'ko_KR') ? true : false;
		},
		/**
		 * 스트리머 썸네일 URL생성
		 * @param {type} szBjID
		 * @returns {String}
		 */
		getBjThumbnail: function (szBjId) {
			if(szBjId === undefined || szBjId === null || szBjId.length === 0){
				return RES_AFREECATV + "/images/afmain/img_thumb_profile.gif"
			}
			var prefix = szBjId.slice(0, 2);
			return this.printf(STIMG_AFREECATV + "/LOGO/%s/%s/%s.jpg", prefix, szBjId, szBjId);
		},

		/**
		 * 어두운 모드 인지 체크
		 * @param {String} szUrl [페이지 링크]
		 * @return {Boolean}
		 */
		checkDark: function (szUrl) { // szUrl => //m.afreecatv.com/#/%s
			// 웹펙으로 묶여 있는 페이지 리스트 (4/16 쪽지, 스트리머 랭킹 적용) , 어두운 모드 추가 할 때 마다 페이지 값 추가
			// 아이템 퀵뷰전환 페이지만 다크모드 적용 (이후, 아이템페이지 전체 적용까진 아래 조건 유지)
			var aDomain = [(AFREECATV_ROOT_DOMAIN || $rootScope.$const.AFREECATV_ROOT_DOMAIN), (SOOPLIVE_ROOT_DOMAIN || $rootScope.$const.SOOPLIVE_ROOT_DOMAIN)];
			var aPage = ['note', 'rank', 'mypoint', 'iteminfo.quickview.upgrade'];
			var regex = new RegExp("(http(s)?:\/\/)m\.(" + aDomain.join('|') + ")\/#\/(" + aPage.join('|') + ")");
			
			// AOS 웹뷰 다크모드 진입 시 깜빡임 이슈 수정
			$('body').removeClass('ag_loading');
			
			return regex.test(szUrl) && (document.cookie.indexOf("theme=dark") > -1);
		},

		/**
		 * mobile.front.main.~.js와 동일
		 *  '<' '>' html entity 처리
		 * @param {String} szTmpHtml 
		 */
		getHtmlEntity: function (szTmpHtml) {
			if (typeof(szTmpHtml) == "undefined" || typeof(szTmpHtml) != "string") {
				return szTmpHtml || "";
			}
			// 검색을 위한 특스문자 엔티티 추가 
			szTmpHtml = szTmpHtml.replace(/&/g, "&amp;");
			szTmpHtml = szTmpHtml.replace(/"/g, "&quot;");
			szTmpHtml = szTmpHtml.replace(/'/g, "&#039;");
			szTmpHtml = szTmpHtml.replace(/</g, "&lt;");
			szTmpHtml = szTmpHtml.replace(/>/g, "&gt;");
			return szTmpHtml;
		},
		sendInflowLog: function (codeType, bjId, contentNo, addData, paramPathKey, sendLogLength, szReferer, vodIdx, list_type, skey){
			var domain = (typeof DOMAIN != 'undefined') ? DOMAIN : $rootScope.$const.SERVICE_ROOT_DOMAIN;
			
			//@TODO. codeType, contentNo, addData, paramPathKey xss 필터링
	    	var logData, inflowLogData;
	        var tmpData = addData;
	        
	        //로그 전송용 경로
	        inflowLogData = this.convertInflowPath.apply(this, tmpData);
	        //쿠키 세팅용 경로 (경로 중 일부만 세팅하고 싶으면 sendLogLength 값으로 slice 처리
	        if(typeof sendLogLength !== 'undefined' && $.isNumeric(sendLogLength) && sendLogLength < 0) {
	            logData = this.convertInflowPath.apply(this, tmpData.slice(0, sendLogLength));
	        } else {
	            logData = inflowLogData;
	        }
	        
	        var referer = (typeof szReferer !== 'undefined' && szReferer !== null) ? szReferer : location.href;                
	        inflowLogData = $.extend({
	    		referer: referer
	    	}, inflowLogData);   
	        
	        var path_key;
	    	var tmToday = new Date();
	         
			// 이미 세팅된 path_key 값 전달 받은게 있다면 그거를 path_key 세팅
			if (typeof paramPathKey !== 'undefined' && paramPathKey !== null && paramPathKey !== '') {
				path_key = paramPathKey;
			} else {
	            //Parse_Cookie(document);
	            //var _au = Read_Cookie("_au");
	            var _au = CookieFactory.get('_au');
	            //@TODO. _au가 없을 경우엔 키를 뭐로 할까나..            
	            path_key = _au + '_' + ((typeof contentNo !== 'undefined' && contentNo !== null && contentNo !== '' && Number(contentNo) !== 0) ? contentNo : bjId) + '_' + tmToday.getTime();
	        }
	        //@TODO. 키를 한번 암호화라도 해야 하려나? 중복되믄 어케?

	    	logData = $.extend({
	    		path_key: path_key
			}, logData);

			// 리스트 내 영상 데이터 타입
			if(typeof list_type !== 'undefined'){
				logData = $.extend({
					list_data_type: list_type
				}, logData);
				inflowLogData = $.extend({
					list_data_type: list_type
			}, inflowLogData);
			}
	    	
			// VOD 리스트 순서
			if(typeof vodIdx !== 'undefined' && vodIdx > 0) {
				logData = $.extend({
					list_idx: vodIdx
				}, logData);

				inflowLogData = $.extend({
					list_idx: vodIdx
				}, inflowLogData);
			}

			// 검색 키워드
			if(typeof skey !== 'undefined') {
				logData = $.extend({
					skey: skey
				}, logData);
			}

	        var name;
	    	var value;
	    	switch (codeType) {
	    		case 'LIVE':
	    			name = 'LIN';
	    			value = $.param(logData);
	    			inflowLogData = $.extend({
	                    bj_id : bjId,
	    				broad_no: contentNo                    
	    			}, inflowLogData);
	    			break;
	    		case 'VOD':
	    			name = 'VIN';
	    			value = $.param(logData);
	    			inflowLogData = $.extend({
	                    bj_id : bjId,
	    				title_no: contentNo
	    			}, inflowLogData);
	    			break;
	    	}

	    	var szUrl = EVENTAPI_AFREECATV + '/set_log_api.php';
	        $.ajax({
	    		type: "POST",
	    		url: szUrl,
	    		data: {
	    			sztype: 'INFLOW_PATH',
	    			code_type: codeType,
	    			path_key: path_key,
	    			send_data: $.param(inflowLogData),
	    			sys_type: 'webm'
	    		},
	    		dataType: 'json',
	    		cache: false,
	    		crossDomain: true,
	    		xhrFields: {
	    			withCredentials: true
	    		},
	    		beforeSend: function (request) {
	    			request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
	    		},
	    		success: function (data) {},
	    		error: function (jqXHR, textStatus, errorThrown) {},
	    		complete: function (jqXHR, textStatus) {}
	    	});

	    	tmToday.setTime(tmToday.getTime() + (10 * 1000)); //10초 후 만료

	    	document.cookie = name + '=' + escape(value) + '; ' +
	    		"path=/; expires=" + tmToday.toGMTString() + '; ' +
	    		"domain=" + domain + ";";
		},
		convertInflowPath: function (path1, path2, path3, path4, path5) {
			var fullpath = {};
	        
	        // 유입 경로 데이터 세팅
			if (path1 !== undefined && path2 !== undefined && path3 !== undefined && path4 !== undefined && path5 !== undefined) {
	            fullpath = {path1:path1, path2:path2, path3:path3, path4:path4, path5:path5};
	        } else if (path1 !== undefined && path2 !== undefined && path3 !== undefined && path4 !== undefined) {
				fullpath = {path1:path1, path2:path2, path3:path3, path4:path4};
			} else if (path1 !== undefined && path2 !== undefined && path3 !== undefined) {
				fullpath = {path1:path1, path2:path2, path3:path3};
			} else if (path1 !== undefined && path2 !== undefined) {
				fullpath = {path1:path1, path2:path2};
			} else if(path1 !== undefined){
				fullpath = {path1:path1};
			} else{
				fullpath = {path1:'etc'};
			}

			return fullpath;
		},
		
		// ' " \ 앞에 붙는 \ 제거 사용 함수
		eacapeBackslash: function (szContent) {
			szContent = szContent.replace(/\\(&#039;)|\\(\')|\\(&quot;)|\\(\")/gi,'$1$2$3$4');
			szContent = szContent.replace(/\\\\/gi,'\\');
			return szContent;
		},

		tagAchange(szPage, szContent, bLoginAdmin, szNoteType, nSubType) {
			var szMobOs = this.getOS();
			var szRegResult = szContent;


			//a 태그 외 다른 태그가 올경우 htmlEntity 추가
			let szFilterContent = (szPage == 'view' && szNoteType != "DONATION") ? this.getHtmlEntity(szRegResult) : szRegResult;

			// 로그인, 보낸이가 유저이고 type이 NOTICE 외일 경우 (일반 유저인 경우)
			if((!bLoginAdmin || typeof(bLoginAdmin) == 'undefined') && szNoteType != 'NOTICE' && !nSubType) {
				return szFilterContent;
			}

			var oRegExp = new RegExp(/(<a href="([^"']+)")(([^>]+).[^>]*?)(.*?)(<\/a>)/, 'gi'); // a 태그 찾을 정규식
			var oRegPms = new RegExp(/[\(|\[](CALLBACK)(\d{1,2})((.*)\|(.*))?[\)|\]]/, 'gi');
			
			var aRegResult = oRegExp.exec(this.htmlDecode(szRegResult));
			var aRegPmsResult = oRegPms.exec(this.htmlDecode(szRegResult));

			if(aRegResult !== null) {
				return this.getNotExistTagA(aRegResult,szRegResult,szPage,oRegExp,szMobOs);
			}
			
			if(aRegPmsResult !== null) {

				var szUrlParameter = "";
				var nKey = Number(aRegPmsResult[2]);
				var oVersioInfo = this.getOSVersion("");
				var pmsCallBack = this.getPmsCallBack(nKey,oVersioInfo);

				if(nKey === 4) {
					szUrlParameter = this.makeSubscribeUrlParameter(oVersioInfo, aRegPmsResult, szMobOs);
				}
				if(szMobOs === 'ios') { 
					return this.iosMakeTagAHtml(pmsCallBack, nKey, aRegPmsResult, oRegPms, szUrlParameter, szPage);
				} else {
					// 6.13.5이상이고 user-agent에 isPlayStore가 y일때 인앱 결제
					var isInAppPayment = Boolean(szMobOs == 'android' && !this.getOSVersion("6.13.5")['compare'] && this.isPlayStore());
					if (isInAppPayment) { 
						if( nKey == 1) { // 퀵뷰 - 선물권은 제외
							return this.aosInAppQuickView(aRegPmsResult, oRegPms, pmsCallBack, szPage);
						} else if(nKey == 4) { // 구독 4
							return this.aosInAppSubscribe(oVersioInfo, aRegPmsResult, oRegPms, pmsCallBack, szPage);
						}
					}
					return this.aosMakeTagAHtml(aRegPmsResult, pmsCallBack, szUrlParameter, oRegPms, szPage);
				}
				
				
			}

			return szFilterContent;
		} ,

		htmlDecode(szContent) {
			szContent = szContent.replace(/&amp;/g, "&");
			szContent = szContent.replace(/&lt;/g, "<");
			szContent = szContent.replace(/&gt;/g, ">");
			szContent = szContent.replace(/&#039;/g, "'");
			szContent = szContent.replace(/&quot;/g, "\"");   
			szContent = szContent.replace(/&nbsp;/g, " ");   
			return szContent;
		},
		getPmsCallBack(nKey,oVersioInfo){
			var szUserId = LoginManager.getUserId();
			var pmsCallBack =  [
				{
					"scheme": "",
					"url": M_AFREECATV + "/#/iteminfo/quickview",
					"name": gtext("사용하러 바로가기")
				},
				{
					"scheme": SERVICE_APP_SCHEME + "item/quickview",
					"url": POINT_AFREECATV + "/mobile/mobilebuyquickview.asp?ndevice=" + (afreeca.link.isMobile() === 'ios' ? 1 : 2 ) + "&szWork=help&szKind=TBOX20131007000000&szUserID=" + szUserId + "&szVersion=" + oVersioInfo['version'] + "&szApp=mafreecatv&szModel=" + oVersioInfo['model'] + "&szOsVer=" + oVersioInfo['os_version'] + "&broadno=&broad_no=&sys_type=" + oVersioInfo['sys_type'] + "&location=item" + oVersioInfo['os'] + (oVersioInfo['store'] === "" ? "": "&store="+oVersioInfo['store']),
					"name": gtext("구매하러 바로가기")
				},
				{
					"scheme": "",
					"url": POINT_AFREECATV + "/Subscription/Mobile/GetSubTokenList.asp?ndevice=" + (afreeca.link.isMobile() === 'ios' ? 1 : 2 ) + "&szWork=help&szKind=TBOX20131007000000&szUserID=" + szUserId + "&szVersion=" + oVersioInfo['version'] + "&szApp=mafreecatv&szModel=" + (afreeca.link.isMobile() === 'ios' ? "" : oVersioInfo['model']) + "&szOsVer=" + oVersioInfo['os_version'] + "&broadno=&broad_no=&sys_type=" + oVersioInfo['sys_type'] + "&location=item"+ oVersioInfo['os'],
					"name": gtext("사용하러 바로가기")
				},
				{
					"scheme": "",
					"url": ITEM_AFREECATV + "/subscription.php",
					"name": gtext("구독 구매하기")
				},
				{
					"scheme": SERVICE_APP_SCHEME + "item/subscription",
					"url": ITEM_M_AFREECATV + "/subscription/a/buy",
					"name": gtext("구독하러 바로가기")
				},
				{
					"scheme": "",
					"url": HELP_AFREECATV + "/m.php?szKind=" + (afreeca.link.isMobile() === 'ios' ? 'TBOX20131007000002' : 'TBOX20131007000000') + "&szApp=mafreecatv&category=NODE0000000066&szUserId="+ szUserId +"&szVersion="+ oVersioInfo['version'] +"&szModel="+ oVersioInfo['model'] +"&szOsVer="+ oVersioInfo['os_version'] +"&szUserID="+ szUserId +"&",
					"name": gtext("1:1 문의 바로가기")
				},
				{
					"scheme": "",
					"url": M_AFREECATV + "/report/help/a/translateMain/szUserId/"+ szUserId +"/szVersion/"+ oVersioInfo['version'] +"/szModel/"+ oVersioInfo['model'] +"/szOsVer/"+ oVersioInfo['os_version'],
					"name": gtext("1:1 문의 바로가기")
				},
				{
					"scheme": "",
					"url": ADBALLOON_AFREECATV + "/savings.php?view=myadballoon",
					"name": gtext("내 애드벌룬 확인하고 선물하기")
				},
				{
					"scheme": "",
					"url": M_AFREECATV + "/itemstore/a/starballoon",
					"name": gtext("결제수단 변경하기")
				},
				{
					"scheme": "",
					"url": M_AFREECATV + "/itemstore/a/starballoon",
					"name": gtext("별풍선 자동충전 신청하기")
				}
			];

			return pmsCallBack[nKey];
		},

		/**
		 * 구독연장 URL 파라미터값 
		 * @param {oVersioInfo} 단말기 정보 
		 * @param {aRegPmsResult} "pms콜백"
		 * @param {szOs} 단말기 타입 (ios,aos)
		 * @returns {szUrlParameter} 구독연장 URL 파라미터값 
		 */
		makeSubscribeUrlParameter(oVersioInfo, aRegPmsResult, szMobOs){
			var szStoreVal = "";
			var szOs = szMobOs === 'android' ? 'aos' : szMobOs;
			var szUrlParameter = "";

			if(oVersioInfo['store'] != ""){
				szStoreVal = '&store='+ encodeURIComponent( oVersioInfo['store']);
			}

			szUrlParameter = 
			"?ticket="+oVersioInfo['ticket']
			+"&device_type="+oVersioInfo['device_type']
			+"&device_version="+oVersioInfo['os_version']
			+"&device_model="+oVersioInfo['model']
			+"&receive_id="+aRegPmsResult[4]
			+"&receive_nick="+aRegPmsResult[5]
			+"&os="+ (szOs != "aos" || szOs != "ios" ? "web" : szOs) 
			+"&sys_type="+oVersioInfo['sys_type']
			+"&location="+oVersioInfo['location']
			+"&payment_type="+oVersioInfo['payment_type']
			+szStoreVal;

			return szUrlParameter;
		},
		/**
		 * iosTagA
		 * @param {pmsCallBack} "pms콜백 종류별 정보"
		 * @param {nKey} "pms콜백 종류 키"
		 * @param {aRegPmsResult} "pms콜백"
		 * @param {oRegPms} "pms콜백 정보"
		 * @param {szUrlParameter} 콜백 URL 파라미터 값
		 * @param {szPage} 페이지 요청 종류
		 * @returns {szResult} TagA -> HTML (완성된 쪽지 내용) 
		 */
		iosMakeTagAHtml(pmsCallBack, nKey, aRegPmsResult, oRegPms, szUrlParameter, szPage){
			var szResult = aRegPmsResult.input;
			if(this.getOSVersion("6.8.2")['compare']) { // 6.8.2 미만 버전(2021/12/14 스킴 추가됨)
				szResult = aRegPmsResult.input.replace(oRegPms,'');
				szResult = $sce.trustAsHtml(szResult);
				return szResult;
			}

			if(pmsCallBack && pmsCallBack.url) {
				var szScheme = pmsCallBack.scheme;
				
				if(nKey == 4){
					szScheme += '?receiverId='+aRegPmsResult[4]+'&receive_nick='+encodeURIComponent(aRegPmsResult[5]);
				}
			
				szResult = aRegPmsResult.input.replace(oRegPms, '(<a href="javascript:;" onClick="goItemMove(\'' + pmsCallBack.url + szUrlParameter + '\', \'' + szScheme + '\');""  style="color:#4279ff;" >' + pmsCallBack.name + '</a>) ');
				szResult = szPage == 'list' ? szResult : $sce.trustAsHtml(szResult);
			}
			return szResult;
		},

		/**
		 * 안드로이드 인앱결제(구독권)
		 * @param {oVersioInfo} 단말기 정보
		 * @param {aRegPmsResult} "pms콜백"
		 * @param {oRegPms} "pms콜백 정보"
		 * @param {szPage} 페이지 요청 종류
		 * @returns {szResult} TagA -> HTML (완성된 쪽지 내용) 
		 */
		// 구독 afreeca://item/subscription?receiverId=${station.user_id}&receive_nick=${encodeURIComponent(station.user_nick)}&bj_image=${encodeURIComponent("https:" + profile_image)}
		aosInAppSubscribe(oVersioInfo, aRegPmsResult, oRegPms, pmsCallBack, szPage){
			var szStoreVal = "";
			var szResult = "";

			if(oVersioInfo['store'] != ""){
				szStoreVal = '&store='+ encodeURIComponent( oVersioInfo['store']);
			}
			
			szResult = aRegPmsResult.input.replace(oRegPms, '(<a href="javascript:;" onClick="goPlayStore(\'' + SERVICE_APP_SCHEME + 'item/subscription'
			+'?receiverId=' + aRegPmsResult[4]
			+'&receive_nick=' + encodeURIComponent(aRegPmsResult[5])
			+'&bj_image=https:' + encodeURIComponent(PROFILE_IMG_AFREECATV + '/LOGO/' + aRegPmsResult[4].substring(0, 2) + '/' + aRegPmsResult[4] + '/' + aRegPmsResult[4] + '.jpg')
			+ szStoreVal 
			+ '\')" style="color:#4279ff;" >' + pmsCallBack.name + '</a>) ');
			
			szResult = szPage == 'list' ? szResult : $sce.trustAsHtml(szResult);
			return szResult; 
		},

		/**
		 * 안드로이드 인앱결제(퀵뷰)
		 * @param {oVersioInfo} 단말기 정보
		 * @param {aRegPmsResult} "pms콜백"
		 * @param {oRegPms} "pms콜백 정보"
		 * @param {pmsCallBack} "pms콜백 종류별 정보"
		 * @param {szPage} 페이지 요청 종류
		 * @returns {szResult} TagA -> HTML (완성된 쪽지 내용) 
		 */
		// 퀵뷰 afreeca://item/billing/googleplay?type=quickview
		aosInAppQuickView(aRegPmsResult, oRegPms, pmsCallBack, szPage){
			var szResult = "";
			
			szResult = aRegPmsResult.input.replace(oRegPms, '(<a href="'+SERVICE_APP_SCHEME+'item/billing/googleplay'
			+'?type=quickview" style="color:#4279ff;" >' + pmsCallBack.name + '</a>) ');
			szResult = szPage == 'list' ? szResult :$sce.trustAsHtml(szResult);

			return szResult; 
		},

		/**
		 * 안드로이드 (원스토어같은 play-store외의 store의 앱 링크)
		 * @param {aRegPmsResult} "pms콜백"
		 * @param {pmsCallBack} "pms콜백 종류별 정보"
		 * @param {szUrlParameter} 콜백 URL 파라미터 값
		 * @param {oRegPms} "pms콜백 정보"
		 * @param {szPage} 페이지 요청 종류
		 * @returns {szResult} TagA -> HTML (완성된 쪽지 내용) 
		 */
		aosMakeTagAHtml(aRegPmsResult, pmsCallBack, szUrlParameter,oRegPms, szPage){
			var szResult = aRegPmsResult.input;
			if(pmsCallBack && pmsCallBack.url) {
				szResult = aRegPmsResult.input.replace(oRegPms, '(<a href="javascript:;" onClick="afreeca.link.browser(\'' + pmsCallBack.url + szUrlParameter + '\');""  style="color:#4279ff;" >' + pmsCallBack.name + '</a>) ');
				szResult = szPage == 'list' ? szResult :  $sce.trustAsHtml(szResult);
			}

			return szResult; 
		},

		/**
		 * tagA가 존재하지 않을 때 
		 * @param {aRegResult} "a 태그 정규식 적용 쪽지 내용"
		 * @param {szRegResult} 변경될 쪽지 내용
		 * @param {szPage} 페이지 요청 종류
		 * @param {oRegExp} "a 태그 찾을 정규식"
		 * @param {szMobOs} 모바일 단말기 종류
		 * @returns {szRegResult} 완성된 쪽지 내용
		 */
		getNotExistTagA(aRegResult,szRegResult,szPage,oRegExp,szMobOs){

			var oRegExpBlank = new RegExp(/(<a href="([^"']+)")(([^>]+)(target="_blank").[^>]*?)(.*?)(<\/a>)/, 'gi'); // 
			var oRegExpLinkName = new RegExp(/(<a href="([^"']+)")(([^>]+)[^\w]|[^\W]+)(.?)(<\/a>)/, 'gi'); // 빈 타이틀이 있는 a 태그 찾을 정규 식

			var aRegLinkNameResult = oRegExpLinkName.exec(this.htmlDecode(szRegResult));

			if(aRegLinkNameResult !== null) { 
				aRegResult.input = aRegResult.input.replace(oRegExpLinkName, "$1$3$2$6");// 링크 사용될 네임이 빈값인 경우 링크 url로 치환
			}
			szRegResult = szPage == 'list' ? aRegResult.input.replace(oRegExp, '$5') :  $sce.trustAsHtml(aRegResult.input); // 리스트 페이지 일때는 링크 명만 (링크 명 없을 경우 url로 표시) ,스타일 속성 적용을 위한 trustAsHtml  추가
			// aos 웹뷰 새창 이슈로 외부 브라우저 호출
			if(szMobOs == 'android' && this.getOSVersion("6.3.0")['compare'] && szPage == 'view' ) { // android이고 상세 페이지 이고 6.3.0 하위 버전일떄 외부 브라우저 호출되도록 수정
				szRegResult = aRegResult.input.replace(oRegExpBlank, '<a href="javascript:;" onClick="goAosMove(\'$2\');"  style="color:#4279ff;" >$6$7'); // target이 blank일때만 
				szRegResult = $sce.trustAsHtml(szRegResult);
			}

			return szRegResult; 
		}
	}
}
module.exports = Util;